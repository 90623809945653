import React from 'react';
import logo from './logo.svg';
import './App.css';
import MyButton from "./components/button";
import Navbar from "./components/navbar";
import AboutSection from "./components/aboutSection";
import IndexSection from "./components/indexSection";
import ToucheSection from "./components/touchSection";
import WorkSection from "./components/workSection";


function App() {
  return (
    <div className="App">
        <Navbar />
        <IndexSection />
        <AboutSection />
        <WorkSection />
        <ToucheSection />


    </div>
  );
}

export default App;
