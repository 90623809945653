import React from 'react';

export default function Navbar() {
    return (
        <nav className="sticky top-0 md:sticky sm:sticky w-full flex items-center justify-between flex-wrap py-6 bg-dark px-6 sm:px-6 md:px-44">
            <div className=" items-center text-white mr-6">
                {/*// <svg className="fill-current h-8 w-8 mr-2" width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg">*/}
                {/*//     <path*/}
                {/*//         d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z"/>*/}
                {/*// </svg>*/}
                <span className="font-light text-xl tracking-tight">Aziz Hikari</span>
            </div>
            <div className="justify-start">
                <a href="#about"
                   className="justify-start  text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-gray-800 hover:bg-white mt-4 lg:mt-0 mx-2">A propos</a>
                <a href="#work"
                   className="justify-start text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-gray-800 hover:bg-white mt-4 lg:mt-0 ml-2">Realisations</a>
            </div>
        </nav>

    );
}


