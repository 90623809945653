import React from 'react';

export default function IndexSection() {
    return (
        <header className="App-header bg-dark">
            <div className="grid grid-cols-1 h-screen">
                <div className="flex items-end justify-center h-50v">
                    <div className="w-4/5">
                        <h1 className="text-6xl sm:text-6xl lg:text-8xl font-normal text-left">
                            Designer - Developer / Digital propulser
                        </h1>

                    </div>
                </div>
                <div className="flex items-center justify-center h-50v">
                    <div className="w-4/5">
                            <ul>
                                <li className="text-left text-3xl sm:text-3xl lg:text-5xl py-2 lg:py-4 font-light c-blue">
                                    {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                                    <a href="mailto:aziz.hikari@gmail.com" target="_blank" rel="Aziz Email noreferrer">aziz.hikari@gmail.com</a>
                                </li>
                                <li className="text-left text-3xl sm:text-3xl lg:text-5xl lg:py-4 font-light">
                                    {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                                    <a href="tel:+33767923536" target="_blank" rel="Aziz Phone noreferrer">+337.67.92.35.36</a>
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
        </header>

    );
}