import React from 'react';
import  about from '../images/about-picture-aziz-mokhtar.jpg';
export default function TouchSection() {
    return (
        <section id="touch" className="bg-dark c-light">
            <div className="px-14 py-14 sm:w-4/5 md:w-3/4 m-auto">
                <div className="grid grid-cols-1 pb-20 pt-20">
                    <div className="flex items-end">
                        <h2 className="text-6xl sm:text-6xl lg:text-8xl font-normal text-left">
                            Travaillons ensemble
                        </h2>
                    </div>
                </div>
                <div className="grid grid-cols-2 max-w pb-10">
                    <hr />
                </div>
                <div className="grid grid-cols-1   pb-20 pt-20">
                    <div className="flex items-left justify-left">
                            <ul>
                                <li className="text-left text-3xl sm:text-3xl lg:text-5xl py-2 lg:py-4 font-light c-blue">
                                    {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                                    <a href="mailto:aziz.hikari@gmail.com"
                                       target="_blank" rel="Aziz Email noreferrer">aziz.hikari@gmail.com</a>
                                </li>
                                <li className="text-left text-3xl sm:text-3xl lg:text-5xl lg:py-4 font-light">
                                    {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                                    <a href="tel:+33767923536" target="_blank" rel="Aziz Phone noreferrer">+337.67.92.35.36</a>
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}