import React from 'react';
import Colorservice from '../images/screen-colorservices.png';
import PermisExpress from '../images/screen-permis-express.png';
import Lynakids from '../images/screen-lynakids.png';
import CleanConfiance from '../images/screen-cleanconfiance.png';

export default function WorkSection() {
    return (
        <section id="work" className="md:px-14 py-14 c-dark min-h-screen w-3/4 m-auto">
            <div className="grid grid-cols-1   pb-20 md:pt-20">
                <div className="flex items-end">
                    <h2 className="text-6xl sm:text-6xl lg:text-8xl font-normal text-left">
                        {/*Latest digital<br/> products works*/}
                        Réalisations récentes en produits digitaux
                    </h2>
                </div>
            </div>

            <div className="grid sm:grid-cols-1 md:grid-cols-2 max-w pb-10">
                <div className="text-left md:pr-12">
                    {/*<div className="w-full h-0 shadow-lg aspect-w-1 aspect-h-1 rounded-xl bg-yellow-300"></div>*/}
                    <div className="hidden sm:block md:hidden">
                        <h3 className="text-4xl font-light text-left pb-4">COLOR SERVICES</h3>
                        <h4>CORPORATE - Wordpress</h4>
                        <h5 className="italic c-blue"><a href="https://color-services.fr/" target="_blank"
                                                         rel="noreferrer">color-services.fr</a></h5>
                        <div className="pb-5"></div>
                        <hr/>
                        <div className="pb-5"></div>

                    </div>
                    <div className="work-block bg-grey-light w-full mb-10 p-4 flex justify-center items-center">
                        <img src={Colorservice} className="w-full max-h-min align-middle" alt="colorservices.fr"/>
                    </div>
                    <div className="block sm:hidden md:block">
                        <hr/>
                        <div className="pb-10"></div>
                        <h3 className="text-4xl font-light text-left pb-4">COLOR SERVICES</h3>
                        <h4>CORPORATE - Wordpress</h4>
                        <h5 className="italic c-blue"><a href="https://color-services.fr/" target="_blank"
                                                         rel="noreferrer">color-services.fr</a></h5>
                    </div>
                </div>
                <div className="text-left md:pr-12">
                    <div className="hidden sm:block md:hidden">
                        <div className="pb-10"></div>
                        <h3 className="text-4xl font-light text-left pb-4">LYNAKIDS</h3>
                        <h4>E-SHOP - Shopify</h4>
                        <h5 className="italic c-blue"><a href="https://lynakids.com/" target="_blank"
                                                         rel="noreferrer">lynakids.com</a></h5>
                        <div className="pb-5"></div>
                        <hr/>
                        <div className="pb-5"></div>
                    </div>
                    <div className="work-block bg-grey-light w-full mb-10 p-4 flex justify-center items-center">
                        <img src={Lynakids} className="w-full max-h-min align-middle" alt="lynakids.com"/>
                    </div>
                    <div className="block sm:hidden md:block">

                        <hr/>
                        <div className="pb-10"></div>
                        <h3 className="text-4xl font-light text-left pb-4">LYNAKIDS</h3>
                        <h4>E-SHOP - Shopify</h4>
                        <h5 className="italic c-blue"><a href="https://lynakids.com/" target="_blank"
                                                         rel="noreferrer">lynakids.com</a></h5>
                    </div>
                </div>
            </div>
            <div className="block sm:hidden md:block grid sm:grid-cols-1 md:grid-cols-2 max-w pb-10"></div>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 max-w pb-10">
                <div className="text-left md:pr-12">
                    {/*<div className="w-full h-0 shadow-lg aspect-w-1 aspect-h-1 rounded-xl bg-yellow-300"></div>*/}
                    <div className="hidden sm:block md:hidden">
                        <div className="pb-10"></div>

                        <h3 className="text-4xl font-light text-left pb-4">PERMIS EXPRESS VILLPINTE</h3>
                        <h4>CORPORATE - Wordpress</h4>
                        <h5 className="italic c-blue"><a href="https://permisexpress-villepinte.com/" target="_blank"
                                                         rel="noreferrer">permisexpress-villepinte.com</a></h5>
                        <div className="pb-5"></div>
                        <hr/>
                        <div className="pb-5"></div>
                    </div>
                    <div className="work-block bg-grey-light w-full mb-10 p-4 flex justify-center items-center">
                        <img src={PermisExpress} className="w-full max-h-min align-middle"
                             alt="permisexpress-villepinte.com"/>
                    </div>
                    <div className="block sm:hidden md:block">
                        <hr/>
                        <div className="pb-10"></div>
                        <h3 className="text-4xl font-light text-left pb-4">PERMIS EXPRESS VILLPINTE</h3>
                        <h4>CORPORATE - Wordpress</h4>
                        <h5 className="italic c-blue"><a href="https://permisexpress-villepinte.com/" target="_blank"
                                                         rel="noreferrer">permisexpress-villepinte.com</a></h5>
                    </div>
                    </div>
                    <div className="text-left md:pr-12">
                        <div className="hidden sm:block md:hidden">
                            <div className="pb-10"></div>

                            <h3 className="text-4xl font-light text-left pb-4">CLEAN CONFIANCE</h3>
                            <h4>CORPORATE - Wordpress</h4>
                            <h5 className="italic c-blue"><a href="http://www.cleanconfiance.com/" target="_blank"
                                                             rel="noreferrer">cleanconfiance.com</a></h5>
                            <div className="pb-5"></div>
                            <hr/>
                            <div className="pb-5"></div>
                        </div>
                        <div className="work-block bg-grey-light w-full mb-10 p-4 flex justify-center items-center">
                            <img src={CleanConfiance} className="w-full max-h-min align-middle"
                                 alt="cleanconfiance.com"/>
                        </div>
                        <div className="block sm:hidden md:block">

                            <hr/>
                            <div className="pb-10"></div>
                            <h3 className="text-4xl font-light text-left pb-4">CLEAN CONFIANCE</h3>
                            <h4>CORPORATE - Wordpress</h4>
                            <h5 className="italic c-blue"><a href="http://www.cleanconfiance.com/" target="_blank"
                                                             rel="noreferrer">cleanconfiance.com</a></h5>
                        </div>
                    </div>
                </div>
        </section>
);
}