import React from 'react';
import  about from '../images/cyclist-5832393_1920.jpg';
export default function AboutSection() {
    return (
        <section id="about" className="px-0 sm:px-0 md:px-14 py-14 c-dark min-h-screen w-4/5 sm:w-4/5 md:w-3/4 m-auto">
            <div className="grid grid-cols-1   pb-20 pt-20">
                <div className="flex items-end">
                    <h2 className="text-5xl sm:text-5xl lg:text-8xl font-normal text-left">
                         Vous accompagne <br/> lors votre digitalisation
                        </h2>
                </div>
            </div>
            <div className="grid sm:grid-cols-1 md:grid-cols-3 max-w pb-10">
                <div className="flex items-start pr-6 mb-10">
                    <p className="text-justify">
                        Passionné par le développement et les technologies innovantes, je suis un développeur dédié avec une solide expérience en création d’applications web et mobiles.
                        Mon objectif est de concevoir des solutions intuitives, performantes et durables, tout en gardant à cœur l'expérience utilisateur.
                        <br /><br />
                        Grâce à une approche collaborative et orientée vers les résultats, je travaille de près avec mes clients pour transformer leurs idées en projets concrets et réussis.
                    </p>
                </div>
                <div className="md:grid-cols-subgrid md:col-span-2 pl-0 sm:pl-0 md:pl-6 text-left ">
                    <img src={about} className="h-auto max-w-full" alt="about Aziz Mokhtar"/>
                </div>
            </div>
            <div className="grid grid-cols-1  pb-20 pt-20">
                <div className="flex items-end">
                    <h2 className="text-5xl sm:text54xl lg:text-6xl font-normal text-left">
                         {/*I can help you with ...*/}
                            Je peux vous aider avec du...
                        </h2>
                </div>
            </div>
            <div className="grid sm:grid-cols-1 md:grid-cols-3 max-w pb-10">
            <div className="text-left sm:pr-0 md:pr-12  pb-12 sm:pb-12">
                <div className="pb-6 c-blue">1.</div>
                    <hr/>
                    <div className="pb-10"></div>
                    <h3 className="text-4xl font-light text-left">Web Design</h3>
                    <p className="text-justify pt-6">
                        La conception web est bien plus qu’une question d’esthétique : elle vise à créer une expérience
                        utilisateur fluide et engageante.
                        En combinant harmonieusement design, interactivité et accessibilité, je développe des sites qui
                        captent l’attention tout en facilitant la navigation.<br/><br/>
                        Mon approche repose sur une compréhension des besoins de l’utilisateur, des tendances modernes
                        et des principes de design responsif pour garantir que chaque projet soit à la fois attractif et
                        fonctionnel sur tous les appareils.


                    </p>
            </div>
                <div className="text-left sm:pr-0 md:pr-12  pb-12 sm:pb-12">
                    <div className="pb-6 c-blue">2.</div>
                    <hr/>
                    <div className="pb-10"></div>
                    <h3 className="text-4xl font-light text-left">Web Developpement</h3>
                    <p className="text-justify pt-6">
                        Le développement web consiste à transformer des idées en applications fonctionnelles,
                        performantes et intuitives.
                        Spécialisé dans les technologies modernes, j'assure un code propre, optimisé et sécurisé pour
                        des sites qui répondent aux besoins spécifiques de chaque projet.<br/><br/>
                        Mon approche privilégie l’efficacité, la scalabilité et l’accessibilité, afin d’offrir aux
                        utilisateurs une expérience de qualité, quel que soit leur appareil. Chaque projet est une
                        opportunité de relever des défis techniques et de concrétiser des visions en solutions digitales
                        robustes et durables.


                    </p>
                </div>
                <div className="text-left sm:pr-0 md:pr-12  pb-12 sm:pb-12">
                    <div className="pb-6 c-blue">3.</div>
                    <hr/>
                    <div className="pb-10"></div>
                    <h3 className="text-4xl font-light text-left">Web Mastering</h3>
                    <p className="text-justify pt-6">
                        Le web mastering englobe la gestion et l'optimisation continue d'un site pour garantir ses
                        performances et sa sécurité. En tant que webmaster, je veille à la mise à jour régulière , à la
                        maintenance technique et à l’amélioration de l’expérience utilisateur.<br/><br/>
                        Mon rôle consiste à assurer le bon fonctionnement du site en surveillant les performances, en
                        résolvant les problèmes techniques.
                        Cette expertise assure un site toujours à jour, sécurisé et adapté aux évolutions du web.
                    </p>
                </div>
            </div>
        </section>
    );
}